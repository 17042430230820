@import '../../vars';

$weekCalendarColumnTopHeight: 35px;

.week-calendar-container {
  display: flex;
  height: 100%;
  width: 100%;
  border-right: solid 1px $light;
}

.week-calendar-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% / 7);
  border-left: solid 2px $light;

  .week-calendar-column-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: $weekCalendarColumnTopHeight;
    padding: 0px 20px;
    border-left: solid 1px $light;

    .week-calendar-column-day-name {
      font-weight: bold;
      color: $darkGray;
    }
  }

  .week-calendar-column-content {
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$weekCalendarColumnTopHeight});
    width: 100%;

    .week-calendar-cell {
      height: calc(100% * 2 / 5);

      &.week-calendar-cell-morning {
        height: calc(100% / 5);
      }
    }
  }
}
