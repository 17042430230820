@import '../../vars';

.nice-select-container {
  position: relative;
  display: inline-flex;
  border-radius: 3px;
  background-color: $white;
  font-size: 0.85em;

  .nice-select {
    display: flex;
    align-items: center;
    padding: 7px 10px;
    border-bottom: solid 2px transparent;
    background-color: $lightest;
    color: $darker;

    &.nice-select-open {
      border-color: $blue;
    }

    &:not(:disabled) {
      cursor: pointer;

      &:hover {
        background-color: $lighter;
      }

      &:active {
        background-color: $light;
      }
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .nice-select-icon {
      margin: 0px 7px 0px 5px;
      color: $dark;
    }
  }

  .nice-select-options-container {
    position: absolute;
    top: 3.2em;
    left: 0;
    max-height: 300px;
    overflow-y: scroll;
    box-shadow: 1px 1px 5px $gray;
    z-index: 10;
  }
}

.nice-select-option {
  padding: 4px 6px;
  white-space: nowrap;
  background-color: $lightest;
  color: $darker;
  cursor: pointer;

  &:hover {
    background-color: $lighter;
  }

  &:active {
    background-color: $light;
  }

  &.is-selected {
    font-weight: bold;
  }
}

.nice-select-buttons-container {
  display: inline-flex;
  text-align: center;
  border-radius: 3px;
  box-shadow: 1px 1px 1px $gray;
  background-color: $white;
  font-size: 0.85em;

  .nice-select-buttons-option {
    padding: 6px 8px;
    background-color: $lightest;
    color: $darker;

    &:not(:disabled):not(.is-selected) {
      cursor: pointer;

      &:hover {
        background-color: $lighter;
      }

      &:active {
        background-color: $lightGray;
      }
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:first-child {
      border-radius: 3px 0px 0px 3px;
    }

    &:last-child {
      border-radius: 0px 3px 3px 0px;
    }

    &::after {
      display: block;
      content: attr(datatext);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &.is-selected {
      background-color: $light;
      font-weight: bold;
      cursor: default;
    }
  }
}
