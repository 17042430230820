@import '../../vars';

$dayCalendarTopHeight: 35px;

.day-calendar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-right: solid 1px $light;

  .day-calendar-top {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $dayCalendarTopHeight;
    width: 100%;
    border-left: solid 1px $light;

    .day-calendar-day-name {
      margin-left: 15px;
      font-weight: bold;
      color: $darkGray;
    }
  }

  .day-calendar-content {
    height: calc(100% - #{$dayCalendarTopHeight});
    width: 100%;
  }
}

.day-calendar-row {
  display: flex;
  height: calc(100% / 12);
  width: 100%;

  .day-calendar-cell {
    height: 100%;
    width: 100%;
  }
}
