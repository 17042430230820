@import '../../vars';

.occurrence-queue {
  height: 100%;
  padding: 10px;
  overflow-y: scroll;
}

.occurrence-cards-list {
  .occurrence-cards-list-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .occurrence-cards-list-header {
      display: flex;
      align-items: center;
      color: $dark;
    }
  }

  .occurrence-cards-list-content {
    margin-top: 20px;
    font-size: 0.85em;

    .no-occurrences {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $darkGray;
      font-weight: bold;
    }
  }
}

.occurrence-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  border-radius: 2px;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 8px;
  }

  &:not(.checked-off):not(.is-scheduled) {
    background-color: $lightRed;
    box-shadow: 1px 1px 1px $red;
    color: $darkRed;
  }

  &.checked-off {
    background-color: $lightGreen;
    box-shadow: 1px 1px 1px $green;
    color: $darkGreen;
  }

  &.is-scheduled {
    background-color: $lightBlue;
    box-shadow: 1px 1px 1px $blue;
    color: $darkBlue;
  }

  &.is-bounded-interval {
    background-color: $lightPurple;
    box-shadow: 1px 1px 1px $purple;
    color: $darkPurple;
  }

  .occurrence-card-top {
    display: flex;
    width: 100%;
    font-weight: bold;
    overflow-x: hidden;
  }

  .occurrence-card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.7em;
    width: 100%;

    .occurrence-card-datetime {
      font-size: 0.85em;

      .recurring-occurrence-icon {
        margin-right: 5px;
      }
    }

    .occurrence-card-actions {
      display: flex;
      align-items: center;
    }
  }
}
