@import '../../vars';

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $topbarHeight;
  padding: 8px;
  background-color: $darkerGray;
  color: $white;

  .topbar-logo {
    height: 100%;
  }

  .topbar-middle {
    display: flex;
    align-items: center;

    .topbar-calendar-control {
      display: flex;
      align-items: center;
    }

    .topbar-calendar-control > * {
      margin: 0px 10px;
    }

    .topbar-selected-month {
      display: flex;
      flex-direction: row-reverse;
      min-width: 150px;
      margin: 0px 20px;
    }
  }
}

.today-button-dot {
  height: 0.9em;
  width: 0.9em;
  margin-right: 6px;
  border-radius: 50%;
  box-shadow: 1px 1px 3px $dark;
  background-color: $lightOrange;
}

.datetime-pager {
  display: flex;
  align-items: center;
  color: $white;
  font-size: 1.3em;

  .datetime-pager-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5em;
    width: 1.5em;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: $darkGray;
    }

    &:active {
      background-color: $gray;
    }

    .previous-page-icon {
      margin-right: 2px;
    }

    .next-page-icon {
      margin-left: 2px;
    }
  }
}
