@import '../../vars';

.edit-event-form {
  padding: 10px;

  .edit-event-form-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-event-form-header {
      display: flex;
      align-items: center;
      color: $dark;
      font-weight: bold;
    }
  }

  .edit-event-form-content {
    margin-top: 30px;

    .edit-event-form-date-select {
      max-width: 50%;
      margin-right: 8px;
    }

    .edit-event-form-time-select {
      max-width: 110px;
    }
  }
}
