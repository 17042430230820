@import '../../vars';

$calendarViewSidebarWidth: 275px;

.calendar-view-container {
  display: flex;
  height: 100%;
  width: 100%;

  .calendar-view-left-sidebar {
    @include transition(
      width $layoutTransitionDuration,
      opacity 0.1s $layoutTransitionDuration
    );
    width: 0;
    opacity: 0;
    overflow-y: scroll;

    &.opened {
      width: $calendarViewSidebarWidth;
      opacity: 1;
    }
  }

  .calendar-container {
    @include transition(width $layoutTransitionDuration);
    width: calc(100% - #{$calendarViewSidebarWidth});

    &.left-sidebar-opened {
      width: calc(100% - 2 * #{$calendarViewSidebarWidth});
    }
  }

  .calendar-view-right-sidebar {
    width: $calendarViewSidebarWidth;
  }
}
