@import '../../vars';

.calendar-occurrence {
  display: flex;
  padding: 0px 4px;
  border-radius: 2px;
  background-color: $lightBlue;
  border: solid 2px $lightBlue;
  --box-shadow-x: 1px;
  --box-shadow-y: 1px;
  --box-shadow-spread-radius: 0px;
  --box-shadow-color: #{$blue};
  box-shadow: var(--box-shadow-x) var(--box-shadow-y) 1px
    var(--box-shadow-spread-radius) var(--box-shadow-color);
  color: $darkBlue;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:not(.flow-horizontal) {
    min-height: 1.5em;
    width: 100%;
  }

  &.flow-horizontal {
    height: 2em;
    width: 200px;
    margin-left: 8px;
  }

  &.has-occurred {
    background-color: $lightRed;
    border-color: $lightRed;
    --box-shadow-color: #{$red};
    color: $darkRed;
  }

  &.checked-off {
    background-color: $lightGreen;
    border-color: $lightGreen;
    --box-shadow-color: #{$green};
    color: $darkGreen;
  }

  &.being-edited {
    --box-shadow-x: 0px;
    --box-shadow-y: 0px;
    --box-shadow-spread-radius: 3px;
    font-style: italic;
  }

  &.is-bounded-interval {
    background-color: $lightPurple;
    border-color: $lightPurple;
    --box-shadow-color: #{$purple};
    color: $darkPurple;
  }

  .calendar-occurrence-details {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(.flow-horizontal) {
      @include transition(
        width $layoutTransitionDuration,
        left $layoutTransitionDuration
      );
      width: 0;
      position: relative;
      left: -200%;

      &.is-hovered {
        width: 100%;
        left: 0;
      }
    }

    &.flow-horizontal {
      flex-direction: row-reverse;

      .calendar-occurrence-actions {
        margin-left: 10px;
      }
    }

    .calendar-occurrence-actions {
      display: flex;
      align-items: center;

      .calendar-occurrence-action-button {
        &:not(:first-child) {
          margin-left: 10px;
        }

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  .calendar-occurrence-preview {
    display: flex;
    align-items: center;

    &:not(.flow-horizontal) {
      @include transition(
        width $layoutTransitionDuration,
        left $layoutTransitionDuration
      );
      width: 200%;
      position: relative;
      left: 0;

      &.is-hovered {
        width: 0;
        left: 200%;
      }
    }

    &.flow-horizontal {
      margin-left: 10px;
    }

    .recurring-occurrence-icon {
      margin-right: 3px;
    }
  }
}
