@import 'vars';

body {
  margin: 0;
  padding: 0;
  font-family: 'Verdana', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $dark;
  background-color: $lightest;
}

* {
  box-sizing: border-box;
}

// apparently different browsers have different defaults for input box-sizing
input {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

/*
Miscellaneous Documentation

z-index levels
--------------
- 10
  - select options container
*/
