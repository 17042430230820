@import '../../vars';

.calendar-cell {
  display: flex;
  justify-content: space-between;
  padding: 4px 8px 8px;
  border-top: solid 1px $light;
  border-left: solid 1px $light;
  font-size: 0.8em;
  overflow-y: hidden;

  &:not(.flow-horizontal) {
    flex-direction: column;
  }

  &.is-now-cell {
    border: solid 2px $lightOrange;
    box-shadow: 0px 0px 3px $darkGray;
  }

  &.is-in-the-past {
    background-color: $lightest;
  }

  &.not-selected-zoom {
    background-color: $lighter;
  }

  .calendar-cell-header {
    display: flex;
    justify-content: space-between;

    &:not(.flow-horizontal) {
      align-items: center;
      width: 100%;
    }

    &.flow-horizontal {
      flex-direction: column;
      align-items: flex-start;
      width: initial;
      min-width: 75px;
    }

    .calendar-cell-number {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
    }

    .calendar-cell-day-name {
      font-weight: bold;
      color: $darkGray;
    }
  }

  .calendar-cell-content {
    flex-grow: 1;
    display: flex;

    &:not(.flow-horizontal) {
      flex-direction: column;
      width: 100%;
      margin: 6px 0px;
    }

    &.flow-horizontal {
      flex-wrap: wrap;
      margin: 0px 6px;
    }
  }

  .calendar-cell-footer {
    display: flex;
    justify-content: flex-end;
    height: 2em;
    font-size: 0.8em;

    &.flow-horizontal {
      flex-direction: column;
      height: initial;
      width: 2em;
    }
  }
}
