@import '../../vars';

.nice-form-row {
  display: flex;
  justify-content: space-between;
}

.nice-form-row:not(:first-child) {
  margin: 20px 0;
}

.nice-form-submit-row {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5em;
}

.nice-form-error-msg {
  position: absolute;
  top: -2.8em;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  color: $red;
  font-size: 0.8em;
}
